import { NgModule } from '@angular/core';
import { MainLayoutComponent } from './main-layout.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AsyncPipe, NgClass, NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { HeaderModule } from 'header';
import { FooterModule } from 'footer';
import { BreadcrumbModule } from 'breadcrumb';


@NgModule({
  declarations: [MainLayoutComponent],
  imports: [
    RouterModule,
    MatSidenavModule,
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    NgStyle,
    NgClass,
    MatSelectModule,
    BreadcrumbModule,
    MatDialogModule,
    AsyncPipe,
    MatButtonModule,
    NgForOf,
    HeaderModule,
    FooterModule,
    NgSwitchCase,
    NgSwitch,
    NgSwitchDefault
  ],
  exports: [MainLayoutComponent, RouterModule]
})
export class MainLayoutModule {

}
