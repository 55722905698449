<lib-dialog-title [title]="'we-call-back' | translate"></lib-dialog-title>

<mat-dialog-content class='dialog-container'>
  <form [formGroup]='formCallBack' (ngSubmit)='onCreateCallBackOrder()'>
    <mat-form-field appearance='outline' class='w-100'>
      <mat-label>{{ 'your-phone-number' | translate }}</mat-label>
      <input
        matInput
        type='text'
        prefix='+38'
        [mask]="'(000) 000-0099'"
        formControlName='phone'
      />
      <mat-error
        *ngIf="formCallBack.controls['phone'].hasError('required')"
      >
        {{ 'input-phone-number' | translate }}
      </mat-error>
      <mat-error *ngIf="formCallBack.controls['phone'].hasError('pattern')">
        {{ 'incorrect-code-phone-provider' | translate }}
      </mat-error>
      <mat-error
        *ngIf="formCallBack.controls['phone'].hasError('minlenght')"
      >
        {{ 'incorrect-phone-number' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance='outline' class='w-100'>
      <mat-label>{{ 'when-call' | translate }}</mat-label>
      <mat-select formControlName='time'>
        <mat-option value='1' *ngIf='hour >= 9 && hour < 19'>{{
          'now' | translate
          }}</mat-option>
        <mat-option value='2' *ngIf='hour > 0 && hour < 19'>{{
          'to-day' | translate
          }}</mat-option>
        <mat-option value='3'>{{
          'tomorrow' | translate
          }}</mat-option>
      </mat-select>
      <mat-error *ngIf="formCallBack.controls['time'].hasError('required')">
        {{ 'select-time-contact' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance='outline' class='w-100'>
      <mat-label>{{ 'name' | translate }}</mat-label>
      <input matInput type='text' formControlName='name' />
      <mat-error *ngIf="formCallBack.controls['name'].hasError('required')">
        {{ 'input-name' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance='outline' class='w-100'>
      <mat-label>{{ 'comment' | translate }}</mat-label>
      <textarea formControlName='comment' matInput rows='3'></textarea>
    </mat-form-field>
    <button mat-button type='submit' class='btn-block btn-primary'>
      {{ 'call-back-name-btn' | translate }}
    </button>
  </form>

  <div class='contact-call-block mt-2'>
    <div>
      <div class='block-info-default'>
        {{ 'call-center-title' | translate }}
      </div>
      <p *ngFor='let item of contactPhoneInfoList'>
        <a
          [href]='item.linkPhone'
          class='link'
          rel='nofollow'
          (click)='clickNumber(item.callPhone)'
        >{{ item.titlePhone}}</a>
      </p>
      <p>{{email}}</p>
    </div>
    <div>
      <div class='block-info-default'>
        {{ 'work-chart' | translate }}
      </div>
      <p *ngFor='let item of workDay'>{{ item.title | translate }}: {{ item.time }}</p>
    </div>
  </div>

  <div class='text-center'>
    <a *ngFor='let item of channelList' [href]='item.link | safe' target='_blank'>
      <img
        [ngSrc]='item.icon'
        [alt]='item.title'
        class='mr-2'
        [matTooltip]='item.title'
        loading='lazy'
        width='46'
        height='46'
        matTooltipClass='tooltip'
      />
    </a>
  </div>
</mat-dialog-content>
