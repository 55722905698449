<div style='position: relative'>
  <div class='header-block header-shadow'>
    <a [routerLink]='[mainPage]'>
      <img [ngSrc]='defaultValue.logo' alt='logo' width='286' height='84'>
    </a>
    <button mat-button class='btn btn-primary button-catalog' (click)='switchCatalog($event)'>
      <div class='catalog-btn'>
        <img
          [ngSrc]='(showCatalog$|async)?closeIcon:catalogIcon'
          alt='Корзина'
          loading='lazy'
          width='26'
          height='26'
        />
        <div>{{'catalog'|translate}}</div>
      </div>
    </button>
    <div class='phone-number-block'>
      <a
        [href]='defaultValue.defaultPhoneLinkMts'
        rel='nofollow'
        (click)='clickNumber()'
      >
        <span class='h1'>095 </span>
        <span class='h1'>021-91-38</span>
      </a>
      <div (click)='loadContactInfoModal()'>
        <img
          class='btn-show-more'
          [ngSrc]='dropImg'
          alt='Еще'
          loading='lazy'
          width='24'
          height='24'
        />
      </div>

    </div>

    <div
      [matTooltip]="'basket' | translate"
      matTooltipClass='tooltip'
      class='pt-2 cart-badge select-car'
      (click)='openCart()'
    >
      <img
        [ngSrc]='cartIcon'
        alt='Корзина'
        loading='lazy'
        width='26'
        height='26'
        style='margin-top: 0.6rem'
      />
      <span
        *ngIf='countProduct$ | async as countProduct'
        [matBadge]="+countProduct == 0 ? '' : countProduct"
      >
        </span>
    </div>
  </div>
  <div class='header-block-mobile header-shadow'>
    <div>
      <a [routerLink]='[mainPage]'>
        <img [ngSrc]='defaultValue.logo' alt='logo' width='160' height='46'>
      </a>
      <div class='phone-number-block'>
        <a
          [href]='defaultValue.defaultPhoneLinkMts'
          rel='nofollow'
          (click)='clickNumber()'
        >
          <span class='h2'>095 </span>
          <span class='h2'>021-91-38</span>
        </a>
        <div (click)='loadContactInfoModal()'>
          <img
            class='btn-show-more'
            [ngSrc]='dropImg'
            alt='Еще'
            loading='lazy'
            width='24'
            height='24'
          />
        </div>

      </div>

    </div>
    <div class='action-block'>
      <div
        [matTooltip]="'basket' | translate"
        matTooltipClass='tooltip'
        class='pt-2 cart-badge select-car'
        (click)='openCart()'
      >
        <img
          [ngSrc]='cartIcon'
          alt='Корзина'
          loading='lazy'
          width='26'
          height='26'
          style='margin-top: 0.6rem'
        />
        <span
          *ngIf='countProduct$ | async as countProduct'
          [matBadge]="+countProduct == 0 ? '' : countProduct"
        >
        </span>
      </div>

      <div
        [matTooltip]="'menu' | translate"
        matTooltipClass='tooltip'
        class='pt-2 cart-badge select-car'
        (click)='openMenu()'
      >
        <img
          [ngSrc]='menuIcon'
          alt='Меню'
          loading='lazy'
          width='26'
          height='26'
          style='margin-top: 0.6rem'
        />
      </div>
    </div>
  </div>

  <lib-catalog-products
    class='catalog-block'
    (closeCatalogEvent)='closeCatalog()'
    [ngStyle]="{'display':(showCatalog$|async)?'block':'none'}"
  ></lib-catalog-products>

</div>
