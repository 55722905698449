import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import {
  ApiRequest,
  IResponseServer,
  ITreeGroup,
  ITreeList,
  SrvService,
} from 'repositories';
import { map } from 'rxjs/operators';
import { CatalogProductsService } from './catalog-products.service';

@Component({
  selector: 'lib-catalog-products',
  templateUrl: './catalog-products.component.html',
  styleUrls: ['./catalog-products.component.scss'],
  providers: [CatalogProductsService],
})
export class CatalogProductsComponent {
  @Output() closeCatalogEvent = new EventEmitter();

  subTreeList$ = new BehaviorSubject<ITreeGroup>(<ITreeGroup>{});
  productTreeList$ = new BehaviorSubject<ITreeList[]>(<ITreeList[]>{});

  productTreeList: ITreeGroup[] = [];
  selectedTree: ITreeGroup = <ITreeGroup>{};
  hoveredTree: number = 0;
  hoveredSubTree: number = 0;
  chevronIcon = '/assets/siteImg/chevron-down/chevron_right_24dp_000000.svg';


  public get mainPage(): string {
    return this._srvService.localizationUrl.getValue();
  }

  constructor(
    private elementRef: ElementRef,
    private readonly _catalogProductsService: CatalogProductsService,
    private readonly _srvService: SrvService
  ) {}

  loadPageInfo$: Observable<ITreeGroup[]> = this._srvService.local.pipe(
    switchMap((value) => {
      return this._catalogProductsService.getNewSite(
        ApiRequest.catalog.nameNew,
        ApiRequest.catalog.method.getAllTreeList,
        { lang: value }
      );
    }),
    map((data: IResponseServer<ITreeGroup[]>) => {
      return data?.result ?? [];
    }),
    tap((list) => {
      this.productTreeList = list;
      this.hoverTree(list[0], 0);
    })
  );

  selectTree(tree: ITreeGroup) {
    this.closeCatalog();
  }

  hoverTree(tree: ITreeGroup, index: number) {
    this.hoveredTree = index;
    this.subTreeList$.next(tree);
    this.hoverSubTree(tree.treeList[0].treeList, 0);
  }

  hoverSubTree(tree: ITreeList[], index: number) {
    this.hoveredSubTree = index;
    this.productTreeList$.next(tree);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const catalogElement = this.elementRef.nativeElement as HTMLElement;
    if (clickedElement === catalogElement) {
      this.closeCatalog();
    }
  }

  closeCatalog(): void {
    this.closeCatalogEvent.emit();
  }
}
