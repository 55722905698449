import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  ApiRequest, DefaultValue,
  IBreadcrumb,
  IResponseServer,
  JsonLDService,
  LocalStorage,
  SrvService,
  WINDOW
} from 'repositories';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MainLayoutService } from './main-layout.service';
import { catchError, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../src/environments/environment.prod';

@UntilDestroy()
@Component({
  selector: 'lib-main-layout',
  templateUrl: 'main-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent implements OnInit {
  loadAlertText$: Observable<{textUa:string, textRu:string}> = this._mainLayoutService.getNewSite(
    ApiRequest.sprDir.nameNew,
    ApiRequest.sprDir.method.getAlertMessage
  ).pipe(
    catchError((error) => {
      if (error.status === 404) {
        // Обработка ошибки 404
        console.error('Alert text error:', error);
        return of({} as IResponseServer<{textUa:string, textRu:string}>)
      } else {
        // Пробрасываем остальные ошибки дальше
        return throwError(() => error);
      }
    }),
    map((data: IResponseServer<{textUa:string, textRu:string}>) => {
      return data?.result ?? {} as {textUa:string, textRu:string};
    })
  );

  public linkHeaderList: {name:string, link:string}[] = [
    { name: 'contact', link: 'contact' },
    { name: 'about', link: 'info-firm' },
    { name: 'delivery-and-payment', link: 'delivery-and-payment' },
    { name: 'assurance', link: 'assurance' },
    { name: 'faq', link: 'faq' }
  ];

  public get showAlertLocalization(): boolean {
    return !!this._localStorage.getItem('showAlertLocalization');
  }

  public get currentLang():string{
    return this._trans.currentLang
  }

  public get isRu(): boolean {
    return this.localization.value === 'ru';
  }

  public get mainPage(): string {
    return this._srvService.localizationUrl.getValue();
  }

  public get breadcrumb(): IBreadcrumb[] {
    return this._srvService.breadcrumb ?? [];
  }

  private organizationSchema = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    address: {
      '@type': 'PostalAddress',
      addressLocality: '10',
      streetAddress: 'вул. М. Грушевського',
      addressRegion: 'Дніпро'
    },
    name: environment.project,
    telephone: DefaultValue.defaultPhoneStrMts
  };
  private searchSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: environment.baseDomain,
    potentialAction: {
      '@type': 'SearchAction',
      target: environment.baseDomain+'/search/{search_term_string}',
      'query-input': 'required name=search_term_string'
    }
  };
  private _websiteSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: environment.baseDomain,
    name: environment.project
  };

  localization = new FormControl();

  constructor(
    private readonly _trans: TranslateService,
    private readonly jsonLd: JsonLDService,
    private readonly _router: Router,
    private readonly _srvService: SrvService,
    private readonly _mainLayoutService: MainLayoutService,
    private readonly _localStorage: LocalStorage,
    private readonly _dialog: MatDialog,
    @Inject(WINDOW) private window: Document
  ) {
    this.localization.setValue(this._localStorage.getItem('localization'));

    if (!this._localStorage.getItem('cartHash')) {
      this._mainLayoutService
        .getRequest(
          ApiRequest.basketClass.name,
          ApiRequest.basketClass.method.createCartHashNew
        )
        .pipe(
          untilDestroyed(this),
          map((data: IResponseServer<{ hash: string }>) => {
            return data?.result ?? <{ hash: string }>{};
          })
        )
        .subscribe((value) => {
          this._localStorage.setItem('cartHash', String(value.hash));
        });
    } else {
      this._mainLayoutService
        .getNewSite(
          ApiRequest.basketClass.nameNew,
          ApiRequest.basketClass.method.getProductCountInBasket,
          { hash: this._localStorage.getItem('cartHash') }
        )
        .pipe(
          untilDestroyed(this),
          map((data: IResponseServer<{ count: number }>) => {
            return data?.result ?? <{ count: number }>{};
          })
        )

        .subscribe((value) =>
          this._srvService.cartCountProduct.next(value.count)
        );
    }
  }

  ngOnInit(): void {
    this.jsonLd.removeStructuredData();
    this.jsonLd.insertSchema(this._websiteSchema);
    this.jsonLd.insertSchema(this.organizationSchema, 'structured-data-org');
    this.jsonLd.insertSchema(this.searchSchema);
  }

  setLocalization(): void {
    this._localStorage.setItem('localization', this.localization.value);
    this._trans.use(this.localization.value);
    this._srvService.localizationStorage = this.localization.value;

    if (this.localization.value === 'ru') {
      this.window.location.href = String('/ru' + this._router.url);
    } else {
      this.window.location.href = this._router.url.replace('/ru', '');
    }
  }

  closeShowAlertLocalization() {
    this._localStorage.setItem('showAlertLocalization', String(true));
  }

  setLocalizationUa() {
    this._localStorage.setItem('localization', 'uk');
    this._trans.use('uk');
    this._srvService.localizationStorage = 'uk';
    this.closeShowAlertLocalization();

    this.window.location.href = this._router.url.replace('/ru', '');
  }
}
