import { Injectable } from '@angular/core';
import { ApiService } from 'repositories';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogProductsService {


  constructor(private readonly _apiService: ApiService) {
  }

  getNewSite(className: string, method: string, value?: any): Observable<any> {
    return this._apiService.getNewSite({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value
      }
    });
  }
}
