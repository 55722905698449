import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalStorage, MemoryStorage, StorageOptions } from '../interface';
import { BaseLocalStorage, BaseMemoryStorage } from '../service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
})
export class StorageModule {
    static forRoot(
        options: Partial<StorageOptions> = {},
    ): ModuleWithProviders<StorageModule> {
        return {
            ngModule: StorageModule,
            providers: [
                {
                    provide: LocalStorage,
                    useClass: options.localStorage || BaseLocalStorage,
                },
                {
                    provide: MemoryStorage,
                    useClass: options.memoryStorage || BaseMemoryStorage,
                },
            ],
        };
    }
}
