import { DefaultValue } from '../../enum/default/default-value.enum';


export const ContactPhoneInfoListConst: {
  name: string;
  linkPhone: string;
  callPhone: string;
  titlePhone: string;
}[] = [
  {
    name: 'Vodafone',
    linkPhone: String(DefaultValue.defaultPhoneLinkMts??''),
    callPhone: String(DefaultValue.defaultPhoneMts??''),
    titlePhone: String(DefaultValue.defaultPhoneStrMts??'')
  },
  {
    name: 'Kyivstar',
    linkPhone: String(DefaultValue.defaultPhoneLinkKvs??''),
    callPhone: String(DefaultValue.defaultPhoneKvs??''),
    titlePhone: String(DefaultValue.defaultPhoneStrKvs??'')
  }
];
