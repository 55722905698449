<lib-dialog-title [title]="'contact-dialog-title' | translate"></lib-dialog-title>
<mat-dialog-content class='dialog-container'>
  <div *ngFor='let item of contactPhoneInfoList' class='contact-phone-block'>
    <a
      [href]='item.linkPhone'
      class='link'
      rel='nofollow'
      (click)='clickNumber(item.callPhone)'
    >{{ item.titlePhone}}</a>
    <div>{{item.name}}</div>
  </div>

  <mat-divider></mat-divider>
  <button
    mat-button
    class='btn-success btn-block contact-call-back-btn'
    (click)='showCallBack()'
  >{{ 'call-back-name-btn' | translate }}</button>
  <mat-divider></mat-divider>

  <div class='row'>
    <div class='col'>
      <h3>{{ 'work-chart' | translate }}</h3>
    </div>
  </div>

  <div class='contact-workday-channel'>
    <div>
      <p *ngFor='let item of workDay'>{{ item.title | translate }}: {{ item.time }}</p>
    </div>
    <div>
      <a *ngFor='let item of channelList' [href]='item.link | safe' target='_blank'>
        <img
          [ngSrc]='item.icon'
          [alt]='item.title'
          class='mr-2'
          [matTooltip]='item.title'
          loading='lazy'
          width='46'
          height='46'
          matTooltipClass='tooltip'
        />
      </a>
    </div>
  </div>
</mat-dialog-content>
