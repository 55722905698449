import { Component } from '@angular/core';
import { DefaultValue, SrvService } from 'repositories';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { CartSmallComponent } from 'cart-small';
import { DialogContactComponent } from 'shared';

@Component({
  selector: 'lib-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent {
  public defaultValue = DefaultValue;
  dropImg: string =
    '/assets/siteImg/chevron-down/arrow_drop_down_white_24dp.svg';
  cartIcon: string = '/assets/siteImg/basket/Group.svg';
  closeIcon: string = '/assets/siteImg/close/Icon_Close.svg';
  catalogIcon: string = '/assets/siteImg/catalog/grid_view_24dp_FFFFFF.svg';
  menuIcon: string = '/assets/siteImg/menu.svg';
  countProduct$: Observable<number> = this._srvService.cartCountProductObs;
  showCatalog$: BehaviorSubject<boolean> = this._srvService.showCatalog$;


  public get mainPage(): string {
    return this._srvService.localizationUrl.getValue();
  }

  constructor(
    private readonly _srvService: SrvService,
    private readonly _dialog: MatDialog
  ) {
  }

  clickNumber(): void {
    this._srvService.clickNumber(this.defaultValue.defaultPhoneMts);
  }

  loadContactInfoModal() {
    this._dialog.open(DialogContactComponent, {
      maxHeight: '100vh',
      minHeight: '100vh',
      minWidth: '100vw',
      maxWidth: '100vw'
    });
  }

  switchCatalog(event: MouseEvent): void {
    event.stopPropagation();
    this.showCatalog$.next(!this.showCatalog$.getValue());
  }

  public openCart(): void {
    this._dialog.open(CartSmallComponent, {
      maxHeight: '95vh',
      maxWidth: '95vw',
      minWidth: '50vw',
      minHeight: '30vh'
    });
  }

  openMenu() {

  }

  closeCatalog() {
    this.showCatalog$.next(false);
  }
}
