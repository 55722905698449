import { NgModule } from '@angular/core';
import { DialogTitleComponent } from './dialog-title.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  declarations: [DialogTitleComponent],
  imports: [
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    NgOptimizedImage
  ],
  exports: [DialogTitleComponent],
})
export class DialogTitleModule {}
