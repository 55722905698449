import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlTree } from '@angular/router';
import { LocalStorage, SrvService, WINDOW } from 'repositories';
import { SwUpdate } from '@angular/service-worker';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

declare let gtag: (
  arg0: string,
  arg1: string,
  arg2: { page_path: any }
) => void;

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  public get loader(): boolean {
    return this.srv.loader;
  }

  ngOnInit(): void {

    const tree: UrlTree = this.router.parseUrl(this.document.location.pathname);
    const path = tree.root.children['primary']?.segments[0]?.path || '';

    if (path === 'ru') {
      this.srv.localizationUrl.next('/' + path);
      this.localStorage.setItem('localization', path);
      this.trans.use(path);
      this.srv.localizationStorage = path;
    } else {
      this.srv.localizationUrl.next('/');
      this.localStorage.setItem('localization', 'uk');
      this.trans.use('uk');
      this.srv.localizationStorage = 'uk';
    }

    console.log('[AppComponent] SSR-compatible init → lang:', path);


  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private srv: SrvService,
    private updates: SwUpdate,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Document,
    @Inject(PLATFORM_ID) private platformId: any,
    private localStorage: LocalStorage,
    private trans: TranslateService
  ) {


    updates.available
      .pipe(untilDestroyed(this))
      .subscribe(() =>
        updates.activateUpdate().then(() => this.document.location.reload())
      );

    const navEndNavigation = this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd)
    );

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe((val) => {
      if (val['utm_source']) {
        const loadInfo = JSON.parse(
          String(this.localStorage.getItem('utm_info'))
        );
        if (loadInfo) {
          const utmLocalInfo = JSON.parse(
            String(this.localStorage.getItem('utm_info'))
          );
          utmLocalInfo.push({
            date: this.srv.convertDateTime(new Date()),
            source: val['utm_source'],
            campaign: val['utm_campaign'],
            content: val['utm_content'],
            term: val['utm_term'],
            gclid: val['gclid'],
            fph: val['fph'],
            url: this.window.location.href
          });
          this.localStorage.setItem('utm_info', JSON.stringify(utmLocalInfo));
        } else {
          const utmLocalInfoNew = [];
          utmLocalInfoNew.push({
            date: this.srv.convertDateTime(new Date()),
            source: val['utm_source'],
            campaign: val['utm_campaign'],
            content: val['utm_content'],
            term: val['utm_term'],
            gclid: val['gclid'],
            fph: val['fph'],
            url: this.window.location.href
          });
          this.localStorage.setItem(
            'utm_info',
            JSON.stringify(utmLocalInfoNew)
          );
        }
      }

      if (val['fbclid']) {
        const loadInfo = JSON.parse(
          String(this.localStorage.getItem('utm_info'))
        );
        if (loadInfo) {
          const utmLocalInfo = JSON.parse(
            String(this.localStorage.getItem('utm_info'))
          );
          utmLocalInfo.push({
            date: this.srv.convertDateTime(new Date()),
            source: 'facebook',
            campaign: val['utm_campaign'],
            content: val['utm_content'],
            term: val['utm_term'],
            gclid: val['gclid'],
            fph: val['fph']
          });
          this.localStorage.setItem('utm_info', JSON.stringify(utmLocalInfo));
        } else {
          const utmLocalInfoNew = [];
          utmLocalInfoNew.push({
            date: this.srv.convertDateTime(new Date()),
            source: 'facebook',
            campaign: val['utm_campaign'],
            content: val['utm_content'],
            term: val['utm_term'],
            gclid: val['gclid'],
            fph: val['fph']
          });
          this.localStorage.setItem(
            'utm_info',
            JSON.stringify(utmLocalInfoNew)
          );
        }
      }
    });

    navEndNavigation.subscribe((event: NavigationEnd) => {
      if (isPlatformBrowser(this.platformId)) {
        /*       gtag('config', 'UA-111137555-1', {
                page_path: event.urlAfterRedirects,
              });
             gtag('config', 'UA-111137555-1', {
                page_path: event.urlAfterRedirects,
              });*/

        /*     gtag('config', 'G-3J8PYWGW1N', {
               page_path: event.urlAfterRedirects,
             });
     */
        /*   gtag('config', 'AW-835608552', {
page_path: event.urlAfterRedirects,
});*/
      }
    });
  }
}
