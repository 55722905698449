import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { RouterLink } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgForOf, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'repositories';



@NgModule({
  declarations: [
    FooterComponent
  ],
  imports: [
    RouterLink,
    MatTooltipModule,
    NgOptimizedImage,
    TranslateModule,
    NgForOf,
    SafePipeModule
  ],
  exports: [
    FooterComponent
  ]
})
export class FooterModule { }
