import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { MainLayoutModule } from 'main-layout';
import { MainLayoutComponent } from '../../projects/main-layout/src/lib/main-layout.component';
const sharedChildren = [
  {
    path: '',
    loadChildren: () =>
      import('home-page').then((m) => m.HomePageModule)
  },
  {
    path: 'product/:id',
    loadChildren: () =>
      import('product-page').then((m) => m.ProductPageModule)
  },
  {
    path: 'thank-you',
    loadChildren: () =>
      import('thank-you-page').then((m) => m.ThankYouPageModule)
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('contacts-page').then((m) => m.ContactsPageModule)
  },
  {
    path: 'assurance',
    loadChildren: () =>
      import('assurance').then((m) => m.AssuranceModule)
  },
  {
    path: 'faq',
    loadChildren: () =>
      import('faq-page').then((m) => m.FaqPageModule)
  },
  {
    path: 'info-firm',
    loadChildren: () =>
      import('info-firm-page').then((m) => m.InfoFirmPageModule)
  },

  {
    path: 'delivery-and-payment',
    loadChildren: () =>
      import('send-delivery-page').then((m) => m.SendDeliveryPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () =>
      import('cart-shopping').then((m) => m.CartShoppingModule)
  },
  {
    path: 'product-sub-group/:groupLink/:subGroupLink',
    loadChildren: () =>
      import('product-sub-group').then((m) => m.ProductSubGroupModule)
  },
  {
    path: 'product-group/:groupLink',
    loadChildren: () =>
      import('product-group-page').then((m) => m.ProductGroupPageModule)
  },
  {
    path: 'tree-prod/:tree',
    loadChildren: () =>
      import('tree-product-page').then((m) => m.TreeProductPageModule)
  },


];

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: sharedChildren
  },
  {
    path: 'ru',
    component: MainLayoutComponent,
    children: sharedChildren
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: NoPreloading
    }),
    MainLayoutModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
