<div mat-dialog-title>
  <div class='dialog-title-block'>
    <div class=' h2'>
      <b>{{ title }}</b>
    </div>
    <button
      type='button'
      mat-button
      class='btn-primary btn-sm'
      [matTooltip]="'close' | translate"
      matTooltipPosition='left'
      matTooltipClass='tooltip'
      matDialogClose
    >
      <img [ngSrc]='closeImg' alt='close' width='8' height='9' />
    </button>
  </div>
</div>
