<lib-dialog-title [title]="'basket' | translate"></lib-dialog-title>

<ng-container *ngIf='loadCartList$ | async as items'>
  <div *ngIf='items.length > 0; else nullCart'>
    <mat-dialog-content style='max-height: 360px'>
      <div  *ngFor='let item of items; let index = index'>
        <mat-card class='mb-2'>
          <mat-card-content class='product-list-block'>
            <div>
              <button
                mat-button
                [matTooltip]="'delete' | translate"
                matTooltipClass='tooltip'
                (click)='onDeleteProd(item.id)'
              >
                <div
                  class='delete-btn-block'
                >
                  <img [ngSrc]='deleteIcon' alt='Убрать из корзины' width='12' height='15' />
                  <span class='h6'>{{
                    'delete' | translate
                    }}</span>
                </div>
              </button>
              <img
                (click)='goToProd(item)'
                [alt]='item.nomen'
                [src]='item.photo'
                class='product-photo'
              />
            </div>
            <div>
              <div class='h5' (click)='goToProd(item)'>{{ item.nomen }}</div>
              <div class='h7'>
                <b>{{ item.katalNumber }} - {{ item.brand }}</b>
              </div>

              <div class='counter-block'>
                <button
                  (click)='onMinusProd(item.id)'
                >-
                </button>
                <input
                  type='text'
                  readonly
                  style='text-align: center; color: #343232'
                  [(ngModel)]='item.count'
                  value='{{ item.count }}'
                />
                <button
                  (click)='onPlusProd(item.id)'
                > +
                </button>
              </div>

              <div class='h3 mt-2' style='border-radius: 5px; font-size: 1rem'>
                <b>{{ item.siteCost }} <span class='caption'>грн.</span></b>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-dialog-content>
    <mat-dialog-content>

      <mat-card>
        <mat-card-content class='total-block'>
          <span>{{ 'cart-total' | translate }}:</span>
          <span class='text-success'>{{ countProd }} грн.</span>
        </mat-card-content>
      </mat-card>
      <a
        mat-button
        class='btn-success success-block'
        [routerLink]="[mainPage, 'shopping-cart']"
        matDialogClose=''
      >{{ 'create-cart-order' | translate }}</a
      >


      <form [formGroup]='buyOneClickForm' (ngSubmit)='onCreateNewOrderByPhone()'>
        <div class='one-buy-click-block'>
          <div class='one-click-block'>
            <input
              (click)='isClick()'
              id='oneClick'
              type='text'
              placeholder='+38 (___) ___-__-__'
              [prefix]="isClicked ? '+38' : ''" [mask]="' (000) 000-0099'"
              formControlName='phone'
            />
            <div
              class='text-danger error-text'
              *ngIf="
                buyOneClickForm.controls.phone.hasError('required') &&
                buyOneClickForm.controls.phone.value
              "
            >
              {{ 'input-phone-number' | translate }}
            </div>
            <div
              class='text-danger error-text'
              *ngIf="buyOneClickForm.controls.phone.hasError('minlength')"
            >
              {{ 'incorrect-phone-number' | translate }}
            </div>

            <div
              class='text-danger error-text'
              *ngIf="buyOneClickForm.controls.phone.hasError('pattern')"
            >
              {{ 'incorrect-code-phone-provider' | translate }}
            </div>
          </div>
          <button
            type='submit'
            mat-button
            class='btn-primary btn-block plerdy-create-order-one-click'
          >
            {{ 'buy-one-click' | translate }}
          </button>
        </div>
      </form>

      <div matDialogClose='' class='h6 text-primary text-center cursor-pointer'>
        <b>{{ 'continue-payment' | translate }}</b>
      </div>
    </mat-dialog-content>
  </div>
  <ng-template #nullCart>
    <mat-dialog-content>
      <mat-card>
        <mat-card-content>
          <div class='empty-cart-block'>
            <img [ngSrc]='cartIcon' alt='Корзина' width='26' height='26' />
            <div [innerHTML]="'empty-cart' | translate"></div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-dialog-content>
  </ng-template>
</ng-container>

