import { Injectable } from '@angular/core';
import { ApiService } from 'repositories';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartSmallService {
  constructor(private readonly _api: ApiService) {
  }

  postRequest(className: string, method: string, value?: any): Observable<any> {
    return this._api.put({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value
      }
    });
  }

  newSitePut(className: string, method: string, value?: any): Observable<any> {
    return this._api.newSitePut(
      className,
      method,
      value
    );
  }

  getNewSite(className: string, method: string, value?: any): Observable<any> {
    return this._api.getNewSite({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value
      }
    });
  }

  deleteNewSite(className: string, method: string, value?: any): Observable<any> {
    return this._api.deleteNewSite({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value
      }
    });
  }

  getRequest(className: string, method: string, value?: any): Observable<any> {
    return this._api.getSite({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value
      }
    });
  }
}
