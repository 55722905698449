import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ApiRequest, channelListConst, ContactPhoneInfoListConst,
  CreateOrder,
  DefaultValue,
  IResponseServer,
  LocalStorage,
  OrderForm,
  SrvService, workDayConst
} from 'repositories';
import { Router } from '@angular/router';
import { CallBackService } from './call-back.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
import { map } from 'rxjs/operators';
import { finalize } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

declare let gtag: any;

@UntilDestroy()
@Component({
  selector: 'lib-call-back',
  templateUrl: 'call-back.component.html',
  styleUrls:['call-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallBackComponent {
  contactPhoneInfoList = ContactPhoneInfoListConst;
  workDay = workDayConst;
  channelList = channelListConst;
  email = DefaultValue.defaultEmail

  formCallBack: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.pattern(
        '^(050|066|095|099|091|063|073|093|094|067|092|096|097|098|068|039|031|032|033|034|035|036|038|041|043|044|045|046)[0-9]*'
      )
    ]),
    time: new FormControl('', [Validators.required]),
    comment: new FormControl('', []),
    vin: new FormControl('', [])
  });
  now = new Date();
  hour = this.now.getHours();

  constructor(
    private readonly _srvService: SrvService,
    private readonly _router: Router,
    private readonly _callBackService: CallBackService,
    private readonly _localeStorage: LocalStorage,
    private readonly _dialog: MatDialogRef<CallBackComponent>,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }

  clickNumber(phone: number | string): void {
    this._srvService.clickNumber(phone);
  }

  onCreateCallBackOrder(): any {
    if (this.formCallBack.invalid) {
      this._srvService.errorPopUp('Заповніть форму');
      return false;
    }

    this._srvService.loader = true;

    const utm = String(this._localeStorage.getItem('utm_info')) ?? null;
    this._callBackService
      .postRequest(
        ApiRequest.catalog.name,
        ApiRequest.catalog.method.onCreateOrderToCallBack,
        {
          ...this.formCallBack.value,
          statusForm: OrderForm.CALL_BACK,
          utm_label: JSON.parse(utm),
        }
      )
      .pipe(
        untilDestroyed(this),
        map((data: IResponseServer<CreateOrder>) => {
          return data?.result ?? null;
        }),
        finalize(() => (this._srvService.loader = false))
      )
      .subscribe(
        (value) => {
          if (isPlatformBrowser(this.platformId)) {
            gtag('event', 'order', { event_category: 'call_back' });
          }

          this._localeStorage.setItem(
            'orderNumber',
            String(value?.orderNumber)
          );
          this._dialog.close();
          this._router.navigate([
            this._srvService.localizationUrl.getValue(),
            'thank-you',
            'call-back'
          ]).then();
        },
        (error) => {
          this._srvService.errorPopUp(
            'Помилка під час створення. Зверніться на лінію консультації (050) 505-31-00'
          );
          this._srvService.loader = false;
          console.error(error);
        }
      );
  }

}
