export const BreadcrumbMockUp = {
  ru: {
    main: 'Главная',
    actualProd: 'Актуальные товары',
  },
  uk: {
    main: 'Головна',
    actualProd: 'Актуальні товари',
  },
};
