import { Injectable } from '@angular/core';
import { ApiService } from 'repositories';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainLayoutService {
  constructor(private api: ApiService) {}

  getRequest(className: string, method: string, value?: any): Observable<any> {
    return this.api.getSite({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }

  getNewSite(className: string, method: string, value?: any): Observable<any> {
    return this.api.getNewSite({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }

  postRequest(className: string, method: string, value?: any): Observable<any> {
    return this.api.post({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }
}
