<div class='wrap'>
  <div class='header-info-block'>
    <div
      class='language-alert-text'
      *ngIf='!showAlertLocalization && isRu'
    >
      {{ 'change-ua' | translate }}
      <button mat-button class='btn-alert' (click)='setLocalizationUa()'>
        {{ 'change-ua-yes' | translate }}
      </button>
      &ngsp;
      <button
        mat-button
        class='btn-primary-outline btn-primary-border'
        (click)='closeShowAlertLocalization()'
      >
        {{ 'change-ua-no' | translate }}
      </button>
    </div>
    <div class='company-info-block'>
      <div class='' *ngFor='let item of linkHeaderList'>
        <a [routerLink]='[mainPage, item.link]'>{{
          item.name | translate
          }}</a>
      </div>
      <div class='localization-select-width-block'>
        <mat-select
          [formControl]='localization'
          (selectionChange)='setLocalization()'
        >
          <mat-option value='uk'>UA</mat-option>
          <mat-option value='ru'>RU</mat-option>
        </mat-select>
      </div>
    </div>
  </div>
  <!--Для ПК-->
  <ng-container *ngIf='loadAlertText$ | async as loadAlertText'>
    <div class='alert-message'>
      <ng-container [ngSwitch]='currentLang'>
        <ng-container *ngSwitchCase="'ru'">
          <div [innerHTML]='loadAlertText?.textRu'></div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div [innerHTML]='loadAlertText?.textUa'></div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <lib-header class='header'></lib-header>
  <div *ngIf='breadcrumb.length>0'
       class='container min-height-50'
       lib-breadcrumb
       [linkList]='breadcrumb'
  ></div>
  <router-outlet></router-outlet>
  <lib-footer></lib-footer>
</div>
