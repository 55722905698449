import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class JsonLDService {
  static scriptType = 'application/ld+json';

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  removeStructuredData(): void {
    const els: Element[] = [];
    ['structured-data', 'structured-data-org'].forEach((c) => {
      els.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });
    els.forEach((el) => this._document.head.removeChild(el));
  }

  removeStructuredProductData(): void {
    const els: Element[] = [];
    ['structured-product'].forEach((c) => {
      els.push(...Array.from(this._document.head.getElementsByClassName(c)));
    });
    els.forEach((el) => this._document.head.removeChild(el));
  }

  insertSchema(
    schema: Record<string, any>,
    className = 'structured-data'
  ): void {
    let script;
    let shouldAppend = false;
    if (this._document.head.getElementsByClassName(className)?.length) {
      script = this._document.head.getElementsByClassName(className)[0];
    } else {
      script = this._document.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    // @ts-ignore
    script.type = JsonLDService.scriptType;
    // @ts-ignore
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this._document.head.appendChild(script);
    }
  }
}
