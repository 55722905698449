import { DefaultValue } from '../../enum/default/default-value.enum';

export const channelListConst: {
  title: string;
  link: string;
  icon: string;
}[] = [
  {
    title: 'Youtube',
    link: String(DefaultValue.youtubeChannel),
    icon: String(DefaultValue.youtubeIcon)
  },
  {
    title: 'Telegram',
    link: String(DefaultValue.telegramChannel),
    icon: String(DefaultValue.telegramIcon)
  },
  {
    title: 'Instagram',
    link: String(DefaultValue.instagramAppChannel),
    icon: String(DefaultValue.instagramIcon)
  }
];
