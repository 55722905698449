import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { finalize, Observable, Subject, switchMap, tap } from 'rxjs';
import { CartSmallService } from './cart-small.service';
import { Router } from '@angular/router';
import {
  ApiRequest,
  ICartSmallProduct,
  IResponseServer,
  LocalStorage,
  SrvService,
} from 'repositories';
import isEqual from 'lodash-es/isEqual';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

declare let gtag: any;
@UntilDestroy()
@Component({
  selector: 'lib-cart-small',
  templateUrl: 'cart-small.component.html',
  styleUrls: ['cart-small.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartSmallComponent implements AfterViewInit {
  deleteIcon: string = '/assets/siteImg/delete/delete-button.svg';
  cartIcon: string = '/assets/siteImg/basket/Group.svg';

  public isClicked = false;

  buyOneClickForm = new FormGroup({
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.pattern(
        '^(050|066|095|099|091|063|073|093|094|067|092|096|097|098|068|039|031|032|033|034|035|036|038|041|043|044|045|046)[0-9]*'
      ),
    ]),
  });
  cartList: ICartSmallProduct[] = [];
  trigger = new Subject();
  public get cartHash(): string {
    return String(this._localeStorage.getItem('cartHash'));
  }

  public get countProd(): number {
    return this._countProd;
  }

  public set countProd(countProd: number) {
    if (isEqual(this._countProd, countProd)) {
      return;
    }

    this._countProd = countProd;
  }

  public get mainPage(): string {
    return this._srvService.localizationUrl.getValue();
  }
  private _countProd: number = 0;

  constructor(
    public _cartSmallService: CartSmallService,
    private _router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CartSmallComponent>,
    private _localeStorage: LocalStorage,
    private _srvService: SrvService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  loadCartList$: Observable<ICartSmallProduct[]> = this.trigger.pipe(
    tap(() => (this._srvService.loader = true)),
    switchMap((value) =>
      this._cartSmallService
        .getNewSite(
          ApiRequest.basketClass.nameNew,
          ApiRequest.basketClass.method.getProductListInBasket,
          value
        )
        .pipe(
          map((data: IResponseServer<ICartSmallProduct[]>) => {
            return data?.result ?? [];
          }),
          tap((response) => {
            this.countProd = 0;

            this.cartList = response;
            response.forEach((cartSmallProduct: ICartSmallProduct) => {
              this.countProd +=
                +cartSmallProduct.count * +cartSmallProduct.siteCost;
            });
          }),
          finalize(() => (this._srvService.loader = false))
        )
    )
  );

  ngAfterViewInit(): void {
    this.trigger.next({ hash: this.cartHash });
  }

  onDeleteProd(id: number): void {
    this._srvService.loader = true;

    this._cartSmallService
      .deleteNewSite(
        ApiRequest.basketClass.nameNew,
        ApiRequest.basketClass.method.deleteProductInBasket,
        { id }
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this._srvService.loader = false;

          this._srvService.cartCountProduct.next(
            +this._srvService.cartCountProduct.getValue() - 1
          );
          this.trigger.next({ hash: this.cartHash });

          this._srvService.successPopUp('Товар видалено з кошика');
        },
        (error) => console.error(error)
      );
  }

  goToProd(product: ICartSmallProduct): void {
    if (isPlatformBrowser(this.platformId)) {
      gtag('event', 'order', { event_category: 'check_out_basket' });
    }
    this._router
      .navigate([
        this.mainPage,
        'product',
        product.katalNumber + '_' + product.brand,
      ])
      .then(() => {
        this.dialog.closeAll();
      });
  }

  onMinusProd(id: number): void {
    this._srvService.loader = true;

    this._cartSmallService
      .newSitePut(
        ApiRequest.basketClass.nameNew,
        ApiRequest.basketClass.method.decrementProductCounter,
        { id }
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this._srvService.loader = false;

          this._srvService.cartCountProduct.next(
            +this._srvService.cartCountProduct.getValue() - 1
          );
          this.trigger.next({ hash: this.cartHash });

          this._srvService.successPopUp('Кількість зменшена');
        },
        (error) => console.error(error)
      );
  }

  onPlusProd(id: number): void {
    this._srvService.loader = true;

    this._cartSmallService
      .newSitePut(
        ApiRequest.basketClass.nameNew,
        ApiRequest.basketClass.method.incrementProductCounter,
        { id }
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this._srvService.loader = false;

          this._srvService.cartCountProduct.next(
            +this._srvService.cartCountProduct.getValue() + 1
          );
          this.trigger.next({ hash: this.cartHash });

          this._srvService.successPopUp('Кількість збільшена');
        },
        (error) => console.error(error)
      );
  }

  onCreateNewOrderByPhone(): void {
    if (this.buyOneClickForm.invalid) {
      this._srvService.errorPopUp('Вкажіть номер телефону');
      return;
    }
    const formValue = this.buyOneClickForm.value;
    const utm = String(this._localeStorage.getItem('utm_info'));

    const dto = {
      /*    id: this.productFastInfo.id,
      cost: this.getCostProduct,*/
      statusForm: 8,
      hashBasket: this.cartHash,
      phone: formValue.phone,
      utm_label: JSON.parse(utm),
    };

    this._cartSmallService
      .postRequest(
        ApiRequest.basketClass.name,
        ApiRequest.basketClass.method.onCreateOrderProdForOneClickBasket,
        dto
      )
      .pipe(
        untilDestroyed(this),
        map((data: IResponseServer<{ orderNumber: number }>) => {
          return data?.result ?? { orderNumber: 0 };
        })
      )
      .subscribe(
        (response) => {
          if (isPlatformBrowser(this.platformId)) {
            gtag('event', 'order', { event_category: 'one_click_basket' });

            const prod: any = [];
            let sum = 0;
            this.cartList.forEach((item) => {
              sum += +item.siteCost * +item.count;
              prod.push({
                item_id: item.katalNumber,
                item_name: `${item.nomenUa} ${item.katalNumber} ${item.brand}`,
                item_category: `${item.nomenUa} ${item.katalNumber} ${item.brand} one_click_basket`,
                price: (+item.siteCost).toFixed(2),
                quantity: item.count,
              });
            });

            gtag('event', 'purchase', {
              transaction_id: response.orderNumber,
              affiliation: 'vedmedyk.in.ua',
              value: sum,
              currency: 'UAH',
              items: prod,
            });
          }

          this._srvService.successPopUp(
            'Замовлення створено ' + response.orderNumber
          );
          this._srvService.cartCountProduct.next(0);
          this._localeStorage.setItem(
            'orderNumber',
            response?.orderNumber?.toString()
          );
          this.dialogRef.close();

          this._router
            .navigate([
              this._srvService.localizationUrl.getValue(),
              'thank-you',
              'order-buy-one-click',
            ])
            .then();
        },
        (error) => {
          console.error(error);
          this._srvService.errorPopUp(
            'Ошибка при створенні. Зверніться на лінію консультації | ' + error
          );
        }
      );
  }

  isClick() {
    this.isClicked = true;
  }
}
