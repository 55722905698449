import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SrvService } from '../service';
import { LocalStorage } from '../interface';
import { environment } from '../../../../../src/environments/environment';

@Injectable({ providedIn: 'root' })
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    // private auth: AuthService,
    private srv: SrvService,
    private router: Router,
    private localStorage: LocalStorage
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {};
    const userToken = this.localStorage.getItem('auth-token');
    // Токен идентификация пользователя
    if (userToken) {
      // @ts-ignore
      headersConfig['auth-token'] = userToken;
    }
    // Токен для доступа к БД
    // @ts-ignore
    headersConfig['token'] = environment.token;
    const request = req.clone({ setHeaders: headersConfig });
    // return next.handle(request);

    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            event = event.clone({ body: this.cleanJsonString(event.body) });
            // Если токен не валидный - выбрасывать пользователя для авторизации
            if (event.body && +event.body?.code === 401) {
              // this.auth.logout();
            }
          }
        },
        (error) => {
            // Фильтруем только нужные запросы для перенаправления
            if (error.status === 404 && req.url.includes('/api/shared')) {
              // Обработка ошибки 404 для определённых запросов
              this.router
                .navigate([this.srv.localizationUrl.getValue(), '404'])
                .then();
            } else if (error.status === 500) {
              this.srv.errorPopUp(
                'Ошибка работы сервера, обратитесь к администратору'
              );
            }
        }
      )
    );
  }

  private cleanJsonString(jsonString: string): string {
    if (typeof jsonString === 'string') {
      return jsonString.trim();
    }
    // Если jsonString не является строкой, вернем его как есть
    return jsonString;
  }
}
