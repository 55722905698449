import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'repositories';

@Injectable({
  providedIn: 'root',
})
export class CallBackService {
  constructor(private readonly _api: ApiService) {}

  postRequest(className: string, method: string, value?: any): Observable<any> {
    return this._api.put({
      modelName: className,
      calledMethod: method,
      methodProperties: {
        ...value,
      },
    });
  }
}
