import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment.prod';

@Injectable()
export class SeoTagService {
  constructor(private title: Title, private meta: Meta) {}

  public setDefaultInfo(title?: string): void {
    this.title.setTitle(
      title
        ? title
        : 'Великі плюшеві іграшки від українського виробника - vedmedyk.in.ua'
    );
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Великі плюшеві ведмедики за доступною ціною з доставкою по Україні. Широкий асортимент. Різні кольори. Приємні ціни.',
      },
      { property: 'og:url', content: environment.baseDomain },
      { property: 'og:type', content: 'website' },
      {
        property: 'og:title',
        content: 'Великі плюшеві іграшки від українського виробника - vedmedyk.in.ua',
      },
      {
        property: 'og:description',
        content:
          'Великі плюшеві ведмедики за доступною ціною з доставкою по Україні. Широкий асортимент. Різні кольори. Приємні ціни.',
      },
      {
        property: 'og:image',
        content: 'https://vedmedyk.in.ua/assets/siteImg/logo.png',
      },
      {
        property: 'og:image:alt',
        content: 'Великі плюшеві іграшки від українського виробника - vedmedyk.in.ua',
      },
    ]);
  }

  setTitle(title: string): void {
    this.title.setTitle(title);
  }

  setTags(tags: any): void {
    const tagList: MetaDefinition[] = [];
    tagList.push(tags.description);
    tagList.push(tags.keywords);
    tagList.push(tags.ogUrl);
    tagList.push(tags.ogType);
    tagList.push(tags.ogTitle);
    tagList.push(tags.ogDescription);
    tagList.push(
      !tags.ogImage
        ? {
            property: 'og:image',
            content:
              'https://vedmedyk.in.ua/assets/siteImg/logo.png',
          }
        : tags.ogImage
    );
    tagList.push(tags.ogImageAlt);

    this.meta.addTags(tagList);
  }
}
