import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { IBreadcrumb, LocalStorage } from '../interface';
import { DatePipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import isEqual from 'lodash-es/isEqual';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiRequest } from '../enum';
import { ApiService } from './api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

declare let gtag: any;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SrvService {
  public get loader(): boolean {
    return this._loader;
  }

  public set loader(loader: boolean) {
    if (isEqual(this.loader, loader)) {
      return;
    }
    this._loader = loader;
    this._loader$.next(loader);
  }

  public set breadcrumb(breadcrumb: IBreadcrumb[]) {
    if (isEqual(this.breadcrumb, breadcrumb)) {
      return;
    }
    this._breadcrumb$.next(breadcrumb);
  }

  public get breadcrumb(): IBreadcrumb[] {
    return this._breadcrumb$.getValue();
  }

  langUrl = new BehaviorSubject(':lang');
  localizationUrl = new BehaviorSubject('');

  public set localizationStorage(obj: string) {
    this.localization$.next(obj);
    this._localeStorage.setItem('localization', obj);
  }

  public get localizationStorage(): string {
    return this._localeStorage.getItem('localization')
      ? String(this._localeStorage.getItem('localization'))
      : 'ru';
  }

  // локализация
  private localization$ = new BehaviorSubject<string>(this.localizationStorage);
  readonly local = this.localization$.asObservable();

  // tree-page
  treePage$ = new BehaviorSubject(false);
  readonly treePageObs = this.treePage$.asObservable();

  // корзина
  cartCountProduct = new BehaviorSubject(0);
  readonly cartCountProductObs = this.cartCountProduct.asObservable();

  windowScrolled = new BehaviorSubject(false);
  readonly windowScrolledObs = this.windowScrolled.asObservable();

  // кол-во найденных товаров
  countProductList = new BehaviorSubject(0);
  // кол-во установленных фильтров
  countFilterList = new BehaviorSubject(0);

  // loader

  private _loader$ = new Subject<boolean>();

  private _loader: boolean = false;
  private _breadcrumb$ = new BehaviorSubject<IBreadcrumb[]>([]);
  showCatalog$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private _snackBar: MatSnackBar,
    private _localeStorage: LocalStorage,
    private _datePipe: DatePipe,
    private _apiService: ApiService,
    @Inject(DOCUMENT) private document: any

  ) {
    this._loader$
      .pipe(untilDestroyed(this))
      .subscribe((value) => (this.loader = value));
  }


  setCanonicalURL(url?: string) {
    const canURL = url === undefined ? this.document.document : url;

    // Найти существующий элемент canonical и удалить его
    const existingLink = this.document.querySelector('link[rel="canonical"]');
    if (existingLink) {
      this.document.head.removeChild(existingLink);
    }

    // Создать новый элемент canonical
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', canURL);
    this.document.head.appendChild(link);
  }

  isMobileDevice(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    } else {
      return true;
    }
  }
  public clickNumber(phone: string|number): void {
    if (isPlatformBrowser(this.platformId)) {
      gtag('event', 'клик на номер', { event_category: 'звонок' });
      const utmInfo = this._localeStorage.getItem('utm_info');
      const utmData = utmInfo ? JSON.parse(utmInfo) : '';

      this._apiService
        .put({
          modelName: ApiRequest.sprDir.name,
          calledMethod: ApiRequest.sprDir.method.setClickNumber,
          methodProperties: { phone, utm: utmData },
        })
        .pipe(untilDestroyed(this))
        .subscribe(() => {});
    }
  }

  public replaceGlobally(
    original: string,
    searchTxt: string,
    replaceTxt: string
  ): any {
    const regex = new RegExp(searchTxt, 'g');
    return original.replace(regex, replaceTxt);
  }

  public convertDate(date: Date): any {
    return this._datePipe.transform(date, 'yyyy-MM-dd');
  }

  public convertDateToJson(date: Date): any {
    return this._datePipe.transform(date, 'yyyy-12-31T23:59:59');
  }

  public convertDateTime(date: Date): any {
    return this._datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
  }

  public successPopUp(message: string): void {
    this._snackBar.open(message, 'Закрити', {
      panelClass: 'snack-bar-success-action',
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public errorPopUp(message: string): void {
    this._snackBar.open(message, 'Закрити', {
      panelClass: 'snack-bar-danger',
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  public primaryPopUp(message: string): void {
    this._snackBar.open(message, 'Закрити', {
      panelClass: 'snack-bar-primary',
      duration: 4000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }
}
