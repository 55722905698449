import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CatalogProductsComponent } from './catalog-products.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';


@NgModule({
  declarations: [
    CatalogProductsComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    TranslateModule,
    RouterLink,
    NgOptimizedImage
  ],
  exports: [
    CatalogProductsComponent
  ]
})
export class CatalogProductsModule {
}
