export enum DefaultValue {
  logo = '/assets/siteImg/logo.png',
  defaultPage = 1,
  defaultDisplayedFilterLength = 5,
  defaultString = '',
  defaultNumber = 0,
  defaultEmail = 'vedmedyk.in.ua@gmail.com',
  defaultDomen = 'Vedmedyk.in.ua',

  defaultPhoneMts = '0950219138',
  defaultPhoneStrMts = '+38 (095) 021-91-38',
  defaultPhoneLinkMts = 'tel:+380950219138',

  defaultPhoneKvs = '0982250960',
  defaultPhoneStrKvs = '+38 (098) 225-09-60',
  defaultPhoneLinkKvs = 'tel:+380982250960',

  /*  defaultPhoneLife = '0635645468',
    defaultPhoneStrLife = '+38 (063) 564-54-68',
    defaultPhoneLinkLife = 'tel:+380635645468',*/

  workTimeWeek = '09:00 - 19:00',
  workTimeSat = '09:00 - 16:00',
  workTimeSan = '10:00 - 16:00',

  youtubeChannel = 'https://youtube.com/@vedmedykukraine8547?si=2Nv-kaLTkxDzTGva',
  //viberChannel = 'viber://pa?chatURI=avto100inua',
  telegramChannel = 'tg://resolve?domain=Vedmedyk_in_ua',
  //whatsAppChannel = 'https://wa.me/+380932686683',
  instagramAppChannel = 'https://instagram.com/bigtoys.ua',

  youtubeIcon = '/assets/siteImg/info/youtube.svg',
  instagramIcon = '/assets/siteImg/instagram.png',
  viberIcon = '/assets/siteImg/info/viber.svg',
  telegramIcon = '/assets/siteImg/info/telegram.png',
  whatsAppIcon = '/assets/siteImg/info/WhatsApp.png'
}
