import { Component } from '@angular/core';
import { channelListConst, ContactPhoneInfoListConst, DefaultValue, SrvService, workDayConst } from 'repositories';
import { MatDialog } from '@angular/material/dialog';
import { CallBackComponent } from 'call-back';

@Component({
  selector: 'lib-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent {
  public defaultValue = DefaultValue;
  contactPhoneInfoList = ContactPhoneInfoListConst;
  workDay = workDayConst;
  channelList = channelListConst;
  email = DefaultValue.defaultEmail;
  footerFirmText = new Date().getFullYear();

  linkList = [
    { name: 'about', link: 'info-firm' },
    { name: 'delivery-and-payment', link: 'delivery-and-payment' },
    { name: 'assurance', link: 'assurance' },
    { name: 'faq', link: 'faq' },
   // { name: 'wholesale', link: 'wholesale' },
   // { name: 'offer', link: 'offer' }
  ];

  paymentSystem = [
    { name: 'VISA', icon: '/assets/siteImg/visa.png' },
    { name: 'MasterCard', icon: '/assets/siteImg/masterCard.png' },
   // { name: 'LiqPay', icon: '/assets/siteImg/liqpay5.png' },
    { name: 'PlataMono', icon: '/assets/siteImg/plata_light_bg.svg' }
  ];

  constructor(
    private readonly _srvService: SrvService,
    private readonly _dialog: MatDialog
  ) {
  }

  clickNumber(phone: number | string): void {
    this._srvService.clickNumber(phone);
  }


  public get mainPage(): string {
    return this._srvService.localizationUrl.getValue();
  }

  showCallBack() {
    this._dialog.open(CallBackComponent, {
      maxWidth: '90vw',
      minWidth: '50vw',
      maxHeight: '95vh'
    });
  }
}
