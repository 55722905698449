import { NgModule } from '@angular/core';
import { CartSmallComponent } from './cart-small.component';
import { AsyncPipe, DecimalPipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { DialogTitleModule } from 'shared';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
  declarations: [CartSmallComponent],
  imports: [
    DialogTitleModule,
    NgIf,
    NgForOf,
    AsyncPipe,
    FormsModule,
    MatDividerModule,
    RouterLink,
    DecimalPipe,
    ReactiveFormsModule,
    DialogTitleModule,
    TranslateModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    NgOptimizedImage,
    MatInputModule,
    MatCardModule,
    NgxMaskDirective
  ],
  exports: [CartSmallComponent],

})
export class CartSmallModule {
}
