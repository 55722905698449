<div class="breadcrumb-list my-3">
    <span *ngFor="let breadcrumb of linkList; let last = last">
        <small *ngIf="!last; else lastLink">
            <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
        </small>
        <ng-template #lastLink>
            <small class="disabled-link">{{ breadcrumb.label }}</small>
        </ng-template>
        <span *ngIf="!last">
            <small> {{ deliminator }} </small>
        </span>
    </span>
</div>
