<div class="container catalog-block">
  <ng-container *ngIf="loadPageInfo$ | async as loadPageInfo">
    <mat-card class="card-block">
      <mat-card-content>
        <div class="title">{{ 'catalog-products' | translate }}</div>
        <div class="main-catalog-overlay">
          <div class="main-group">
            <a
              class="catalog-group-item link"
              [ngClass]="{ 'catalog-group-item-active': index === hoveredTree }"
              *ngFor="let item of loadPageInfo; let index = index"
              [routerLink]="[mainPage, 'product-group', item.mainGroupLink]"
              (click)="closeCatalog()"
              (mouseenter)="hoverTree(item, index)"
            >
              <div>{{ item.mainGroup }}</div>
              <img
                [ngSrc]="chevronIcon"
                width="24"
                height="24"
                alt="chevronIcon"
              />
            </a>
          </div>
          <div class="sub-group-item">
            <ng-container *ngIf="subTreeList$ | async as subTreeList">
              <a
                *ngFor="
                  let itemSub of subTreeList.treeList;
                  let indexSub = index
                "
                [routerLink]="[
                  mainPage,
                  'product-sub-group',
                  subTreeList.mainGroupLink,
                  itemSub.subGroupLink
                ]"
                [ngClass]="{
                  'catalog-group-item-active': indexSub === hoveredSubTree
                }"
                (mouseenter)="hoverSubTree(itemSub.treeList, indexSub)"
                (click)="closeCatalog()"
                class="catalog-group-item link sub-group-title"
              >
                <div>{{ itemSub.subGroup }}</div>
                <img
                  [ngSrc]="chevronIcon"
                  width="24"
                  height="24"
                  alt="chevronIcon"
                />
              </a>
            </ng-container>
          </div>
          <div class="product-group-item">
            <ng-container *ngIf="productTreeList$ | async as productTreeList">
              <a
                class="sub-tree-item link"
                (click)="closeCatalog()"
                [routerLink]="[mainPage, 'tree-prod', val.link]"
                *ngFor="let val of productTreeList"
              >
                <div class="product-link-block">
                  <img [src]="val.icon" [alt]="val.lvl3" />
                  <div>{{ val.lvl3 }}</div>
                </div>
              </a>
            </ng-container>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>
</div>
