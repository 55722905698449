<footer class='footer'>
  <div class='backround-block'>
    <div class='container'>
      <div class='footer-block'>
        <div>
          <a [routerLink]='[mainPage]'>
            <img [ngSrc]='defaultValue.logo' alt='logo' width='200' height='59'>
          </a>
          <p class='city-list' [innerHtml]="'delivery-city-list'|translate"></p>
        </div>
        <div>
          <h3 class='footer-title'>{{'info'|translate}}</h3>
          <a class='link footer-list' *ngFor='let item of linkList'
             [routerLink]='[mainPage,item.link]'>{{item.name|translate}}</a>
        </div>
        <div>
          <h3 class='footer-title'>{{'contact'|translate}}</h3>
          <p (click)='showCallBack()' class='footer-list link'>{{'call-back-name-btn'|translate}}</p>

          <p class='footer-list' *ngFor='let item of contactPhoneInfoList'>
            <a
              [href]='item.linkPhone'
              class='link'
              rel='nofollow'
              (click)='clickNumber(item.callPhone)'
            >{{ item.titlePhone}}</a>
          </p>
          <p class='footer-list'>{{email}}</p>
        </div>
        <div>

          <h3 class='footer-title'>{{'address'|translate}}</h3>
          <p class='footer-list'>{{'marketplace'|translate}}</p>

          <h3 class='footer-title'>{{'work-chart' |translate}}</h3>
          <p class='footer-list' *ngFor='let item of workDay'>{{ item.title | translate }}: {{ item.time }}</p>
        </div>
      </div>
      <div class='text-right mt-2'>
        <a *ngFor='let item of channelList' [href]='item.link | safe' target='_blank'>
          <img
            [ngSrc]='item.icon'
            [alt]='item.title'
            class='mr-2'
            [matTooltip]='item.title'
            loading='lazy'
            width='46'
            height='46'
            matTooltipClass='tooltip'
          />
        </a>
      </div>
      <div class='payment-system-block'>
        <img *ngFor='let item of paymentSystem'
             loading='lazy'
             width='80'
             [src]='item.icon'
             [alt]='item.name'
             class='mr-2'
             [matTooltip]='item.name'
             matTooltipClass='tooltip'
        />

      </div>
      <div class='text-right mt-2'>
        {{'firm-info'|translate}} {{footerFirmText}}
      </div>
    </div>
  </div>
</footer>
