import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BreadcrumbMockUp, IBreadcrumb, SrvService } from 'repositories';
import isEqual from 'lodash-es/isEqual';

@Component({
  selector: '[lib-breadcrumb]',
  templateUrl: 'breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Input()
  public set linkList(breadcrumb: IBreadcrumb[]) {
    if (breadcrumb?.length > 0) {
      breadcrumb.unshift(this.mainItem);
    }

    if (isEqual(this._breadcrumb, breadcrumb)) {
      return;
    }
    this._breadcrumb = breadcrumb;
  }

  public get linkList(): IBreadcrumb[] {
    return this._breadcrumb;
  }

  @Input() deliminator = '>';
  mainPage: string = this._srvService.localizationUrl.getValue();
  private _breadcrumb: IBreadcrumb[] = [];
  private mainItem: IBreadcrumb = {
    label:
      BreadcrumbMockUp[
        this._srvService.localizationStorage as keyof typeof BreadcrumbMockUp
      ].main,
    url: this.mainPage,
  };

  constructor(private readonly _srvService: SrvService) {}
}
