import { Environment } from './interface.environment';

export const environment: Environment = {
  production: true,
  newServer:'https://site.vedmedyk.in.ua/',
  token: 'e45r9823ejjdthng052e23rmuh',
  url: 'https://srv.vedmedyk.in.ua/api/',
  url_jwt: 'https://srv.vedmedyk.in.ua/api_jwt/',
  urlGetSite: 'https://srv.vedmedyk.in.ua/api_get/',
  serverPath: '../browser',
  baseDomain: 'https://vedmedyk.in.ua',
  base: 'vedmedyk.in.ua',
  version: '6.0.0.1',
  companyId: 1,
  project: 'vedmedyk',

};
