import { ChangeDetectionStrategy, Component } from '@angular/core';
import { channelListConst, ContactPhoneInfoListConst, DefaultValue, SrvService, workDayConst } from 'repositories';
import { CallBackComponent } from 'call-back';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'lib-dialog-contact',
  templateUrl: 'dialog-contact.component.html',
  styleUrls: ['dialog-contact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogContactComponent {


  contactPhoneInfoList = ContactPhoneInfoListConst;
  workDay = workDayConst;
  channelList = channelListConst;

  constructor(
    private readonly _srvService: SrvService,
    private readonly _dialog: MatDialog,
    private readonly _dialogRef: MatDialogRef<DialogContactComponent>
  ) {
  }

  clickNumber(phone: number | string): void {
    this._srvService.clickNumber(phone);
  }

  showCallBack() {
    this._dialog.open(CallBackComponent, {
      maxWidth: '90vw',
      minWidth: '50vw',
      maxHeight: '95vh'
    });

    this._dialogRef.close();
  }
}
