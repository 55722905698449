import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorage, MemoryStorage } from '../interface';
import { WINDOW } from '../shared';

@Injectable()
export class BaseLocalStorage implements LocalStorage {
  /**
   * Storage
   */
  private readonly storage: Storage;

  get length(): number {
    return this.storage?.length;
  }

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  constructor(
    private memoryStorage: MemoryStorage,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(WINDOW) private window: Window
  ) {
    this.storage = isPlatformBrowser(this.platformId)
      ? this.window.localStorage
      : this.memoryStorage;
  }
}
