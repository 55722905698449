import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { RouterLink } from '@angular/router';
import { AsyncPipe, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { CatalogProductsModule } from 'shared';



@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [
    RouterLink,
    NgOptimizedImage,
    MatTooltipModule,
    TranslateModule,
    MatBadgeModule,
    AsyncPipe,
    NgIf,
    MatButtonModule,
    CatalogProductsModule,
    NgStyle
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
