import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../../../src/environments/environment';
import { TransferState } from '@angular/platform-browser';

interface RequestBody {
  token?: string; // Ключ
  modelName: string; // Название Класса
  workmanId?: string; // ID Сотрудника
  calledMethod: string; // Вызываемый метод
  methodProperties: object; // Тело запроса
}

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  post(data: RequestBody): Observable<any> {
    return this.http.post(environment.url, data);
  }

  newSitePost(data: RequestBody): Observable<any> {
    return this.http.post(environment.newServer, data);
  }

  put(data: RequestBody): Observable<any> {
    return this.http.put(environment.url, data);
  }

  newSitePut(modelName:string, calledMethod:string, data: RequestBody): Observable<any> {
    return this.http.put(`${environment.newServer}api/${modelName}/${calledMethod}`, data);
  }

  post_jwt(data: RequestBody): Observable<any> {
    return this.http.post(environment.url_jwt, data);
  }

  private getParams(
    modelName: string,
    calledMethod: string,
    methodProperties: { [key: string]: any }
  ): HttpParams {
    return new HttpParams()
      .set('modelName', modelName)
      .set('calledMethod', calledMethod)
      .set('methodProperties', JSON.stringify(methodProperties));
  }

  getSite({
            modelName,
            calledMethod,
            methodProperties
          }: RequestBody): Observable<any> {
    const params = this.getParams(modelName, calledMethod, methodProperties);

    return this.http.get(environment.urlGetSite, { params }).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          try {
            // Попытка разбора JSON-ошибки
            const jsonError = error.error;
            console.error('Server returned an error:', jsonError);
          } catch (jsonErrorParseError) {
            // В случае ошибки разбора JSON
            console.error(
              'Server returned an error, but it cannot be parsed as JSON:',
              error.error
            );
          }
        }
        // Пропагация ошибки
        return throwError(error);
      })
    );
  }

  private getNewParams(methodProperties: { [key: string]: any }): HttpParams {
    let params = new HttpParams();

    // Добавляем параметры, исключая ненужную сериализацию строк
    Object.keys(methodProperties).forEach((key) => {
      const value = methodProperties[key];
      // Если значение уже строка, не сериализуем его
      params = params.set(key, typeof value === 'string' ? value : JSON.stringify(value));
    });

    return params;
  }

  getNewSite({
               modelName,
               calledMethod,
               methodProperties
             }: RequestBody): Observable<any> {
    const params = this.getNewParams(methodProperties);

    return this.http.get(`${environment.newServer}api/${modelName}/${calledMethod}`, { params }).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          try {
            // Попытка разбора JSON-ошибки
            const jsonError = error.error;
            console.error('Server returned an error:', jsonError);
          } catch (jsonErrorParseError) {
            // В случае ошибки разбора JSON
            console.error(
              'Server returned an error, but it cannot be parsed as JSON:',
              error.error
            );
          }
        }
        // Пропагация ошибки
        return throwError(error);
      })
    );
  }

  deleteNewSite({
               modelName,
               calledMethod,
               methodProperties
             }: RequestBody): Observable<any> {
    const params = this.getNewParams(methodProperties);

    return this.http.delete(`${environment.newServer}api/${modelName}/${calledMethod}`, { params }).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          try {
            // Попытка разбора JSON-ошибки
            const jsonError = error.error;
            console.error('Server returned an error:', jsonError);
          } catch (jsonErrorParseError) {
            // В случае ошибки разбора JSON
            console.error(
              'Server returned an error, but it cannot be parsed as JSON:',
              error.error
            );
          }
        }
        // Пропагация ошибки
        return throwError(error);
      })
    );
  }


}
