import { Component, Input } from '@angular/core';
import { DefaultValue } from 'repositories';

@Component({
  selector: 'lib-dialog-title',
  templateUrl: 'dialog-title.component.html',
})
export class DialogTitleComponent {
  @Input() title: string = DefaultValue.defaultString;
  closeImg: string = '/assets/siteImg/close/Icon_Close.svg';
}
