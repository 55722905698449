export const ApiRequest = {
  favoriteClass: {
    name: 'FavoriteProd',
    method: {
      loadFavoriteGroupNew: 'loadFavoriteGroupNew',
      loadFavoriteProductList: 'loadFavoriteProductListNew',
      loadFilterInfo: 'loadFilterInfoNew',
    },
  },
  basketClass: {
    name: 'Basket',
    nameNew: 'basket',
    method: {
      getProductCountInBasket: 'get-product-count-in-basket',
      getProductListInBasket: 'get-product-list-in-basket',
      deleteProductInBasket: 'delete-product-in-basket',
      decrementProductCounter: 'decrement-product-counter',
      incrementProductCounter: 'increment-product-counter',



      addToCart: 'addToCart',
      createCartHashNew: 'createCartHashNew',
      loadCountCartProdNew: 'loadCountCartProdNew',
      loadProductListNew: 'loadProductListNew',
      deleteItem: 'deleteItem',
      minusProd: 'minusProd',
      plusProd: 'plusProd',
      getDeliveryList: 'getDeliveryList',
      getPaymentList: 'getPaymentList',
      getOblast: 'getOblast',
      getCityList: 'getCityList',
      getWarehouseList: 'getWarehouseList',
      onCreateBasketOrderNew: 'onCreateBasketOrderNew',
      saveOrderComment: 'saveOrderComment',
      logBasket: 'logBasket',
      getUserInfo: 'getUserInfo',
      onCreateOrderProdForOneClickBasket: 'onCreateOrderProdForOneClickBasket',
      getCityListNew: 'getCityListNew',
    },
  },
  sprDir: {
    name: 'SprDirect',
    nameNew: 'shared',
    method: {
      getAlertMessage: 'get-alert-message',
      loadAlertInfo: 'loadAlertInfo',
      setClickNumber: 'setClickNumber',
      onLoginNew: 'onLoginNew',
      onRegisterUserNew: 'onRegisterUserNew',
      onConfirmRegCodeNew: 'onConfirmRegCodeNew',
      sendRestorePasswordNew: 'sendRestorePasswordNew',
      sendConfirmCodeNew: 'sendConfirmCodeNew',
      onSetCarById: 'onSetCarById',
      onFeedBack: 'onFeedBack',
    },
  },

  catalog: {
    name: 'CatalogNew',
    nameNew: 'catalog',
    method: {
      getAllTreeList: 'get-all-tree-list',

      loadMenuTreeInfo: 'loadMenuTreeInfo',
      setClickNumber: 'setClickNumber',
      onCreateOrderToCallBack: 'onCreateOrderToCallBack',
      getAllMarka: 'getAllMarka',
      getModel: 'getModel',
      getYear: 'getYear',
      getBodyList: 'getBodyList',
      getEngineList: 'getEngineList',
      getModList: 'getModList',
      onSetCar: 'onSetCar',
      getStartPageInfoSaleNewDesign: 'getStartPageInfoSaleNewDesign',
      getTopCarList: 'getTopCarList',
      onCreateOrderForVinCode: 'onCreateOrderForVinCode',
      getMarkInfo: 'getMarkInfo',
      getMarkIdPerName: 'getMarkIdPerName',
      getMarkModelIdPerName: 'getMarkModelIdPerName',
      getModelInfo: 'getModelInfo',
      getLoadModelTree: 'getLoadModelTree',
      loadPageTreeInfo: 'loadPageTreeInfo',
      loadFilterInfo: 'loadFilterInfo',
      loadProductList: 'loadProductList',
      loadProductListToOnlyTree: 'loadProductListToOnlyTree',
      loadFilterInfoToOnlyTree: 'loadFilterInfoToOnlyTree',
      getLoadMarkTree: 'getLoadMarkTree',
      loadMarkProductList: 'loadMarkProductList',
      loadMarkProductFilterInfo: 'loadMarkProductFilterInfo',
      getLoadBrandTree: 'getLoadBrandTree',
      loadBrandProductFilterInfo: 'loadBrandProductFilterInfo',
      loadBrandProductList: 'loadBrandProductList',
      onCreateOrderProdForVinCode: 'onCreateOrderProdForVinCode',
      onCreateOrderProdForOneClickSale: 'onCreateOrderProdForOneClickSale',
      getVinMarkListNew: 'getVinMarkListNew',
      getVinModelLinkNew: 'getVinModelLinkNew',
      getGroupInfo: 'getGroupInfo',
      getSubGroupInfo: 'getSubGroupInfo',
      getBrandInfo: 'getBrandInfo',
      getTopBrandList: 'getTopBrandList',
      loadProductListToOnlyTreeCarList: 'loadProductListToOnlyTreeCarList',
      onCreateOrderStoForm: 'onCreateOrderStoForm', getPopularTree: 'getPopularTree'

    },
  },
  product: {
    name: 'ProductNew',
    method: {
      getFastInfoAboutProduct: 'getFastInfoAboutProduct',
      getCriList: 'getCriList',
      getCriInfo: 'getCriInfo',
      getBrandInfo: 'getBrandInfo',
      getCarList: 'getCarList',
      getOeList: 'getOeList',
      loadAnalogInfo: 'loadAnalogInfo',
      loadFavoriteProduct: 'loadFavoriteProduct',
      loadSeoText: 'loadSeoText',
      loadSpecTreeInfo: 'loadSpecTreeInfo',
      loadUsedProductForCar: 'loadUsedProductForCar',
      getUsedCarList: 'getUsedCarList',
      loadColorFilter: 'loadColorFilter',
      loadVolumeFilter: 'loadVolumeFilter',
    },
  },
  search: {
    name: 'SearchNew',
    method: {
      getHeaderSearch: 'getHeaderSearch',
      getSearchProductInfo: 'getSearchProductInfo',
      getSearchProductCross: 'getSearchProductCross',
      getSearchProductCrossFilter: 'getSearchProductCrossFilter',
    },
  },
};
